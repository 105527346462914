cookieControl = {
    readCookie: function(e) {
        var e = e.replace(/([.*+?^=!:${}()|[\]\/\\])/g, "\\$1"),
        o = new RegExp("(?:^|;)\\s?" + e + "=(.*?)(?:;|$)", "i"),
        i = document.cookie.match(o);
        return i && unescape(i[1])
    },
   createCookie: function(e, o, i, u) {
        var t = "",
        u = !u ? ".dafiti.com.br" : u;
        c = new Date;
        i && (c.setTime(c.getTime() + 24 * i * 60 * 60 * 1e3), t = "; expires=" + c), document.cookie = e + "=" + o + t + ";domain="+ u +";path=/"
    }
}
